import gql from "graphql-tag";
import { FRAGMENT_MEDIA_ITEM } from "./fragments.js";

export const GET_NEW_HOME_SEARCH = gql`
  ${FRAGMENT_MEDIA_ITEM}
  query GetNewHomeSearch {
    plans(last: 500) {
      nodes {
        databaseId
        id
        uri
        title
        productStatus {
          enableSnipeLink
          fieldGroupName
          sectionId
          statusCustom
          statusMessage
        }
        floorplanDetails {
          basePrice
          bedroomsMax
          bedroomsMin
          bathroomsMin
          bathroomsMax
          collection
          displayTitle
          finishedSqrFt
          homeType
          mainSqrFt
          type
          elevations {
            ...MediaItemFragment
          }
          features {
            feature
          }
          neighborhood {
            ... on Neighborhood {
              databaseId
              id
              slug
              title
              uri
              neighborhood {
                city
                state
              }
            }
          }
        }
      }
    }
  }
`;
